import moment from "moment-timezone";
import Pagination from "@/components/Pagination";
import ListMixin from "@/mixins/list-page.js";

export default {
    name: "LoanList",
    components: {
        Pagination,
    },
    props: {
        userFilter: Boolean,
    },
    mixins: [ListMixin],
    computed: {
        isAuthCsv(){
            return ["senior_collector", "admin"].includes(this.$auth.user().type)
        },
    },
    data() {
        return {
            filterForm: null,
            filters: null,
            statuses: [
                {value: 0, text: "Любой статус"},
                {value: "unknown", text: "неизвестный"},
                {value: "promise_to_pay", text: "Обещание заплатить"},
                {value: "link_3rd_person", text: "Связь с 3-м лицом"},
                {value: "numbers_not_available", text: "Номера не доступны"},
                {value: "recall", text: "Перезвонить"},
                {value: "refuse_interact", text: "Отказ от взаимодействия"},
                {value: "refuse_pay", text: "Отказ от оплаты"},
                {value: "died", text: "Умер"},
            ],
        };
    },
    mounted() {
        this.fields = ([
            {
                key: "id",
                label: "Номер договора",
            },
            {
                key: "fio",
                sortable: true,
                label: "ФИО",
                formatter(value, key, item) {
                    return `${item.lastName} ${item.firstName} ${item.patronymic ?? ""}`;
                },
            },
            {
                key: "borrowerTime",
                label: "Время",
                sortable: true,
                sortByFormatted: true,
                formatter(value, key, item) {
                    const timezone = item.borrowerTimezone ?? "Europe/Moscow";
                    return `${moment.tz(moment.now(), timezone).format("HH:mm")} ${timezone}`;
                },
            },
            {
                key: "lastActionDate",
                sortable: true,
                label: "Последний визит",
                sortByFormatted: true,
                formatter(value, key, item) {
                    if (item.borrower.lastActionDate === null) {
                        return "неизвестно";
                    }

                    return window.formatDatetime(item.borrower.lastActionDate);
                },
            },
            {
                key: "lastCommentAt",
                label: "Последнее общение (дней)", // Дней прошедших с последнего звонка
                sortable: true,
                formatter(value) {
                    if (value !== null) {
                        let now = new Date();
                        let date = new Date( Date.parse(value.trim()) );
                        return Math.floor((now - date) / (1000 * 60 * 60 * 24));
                    }
                    return;
                }
            },
            {
                key: "weekCallCount",
                label: "контактов за неделю",
                sortable: true,
                formatter(value, key, item) {
                    return item.call?.week
                }
            },
            {
                key: "monthCallCount",
                label: "контактов за месяц",
                sortable: true,
                formatter(value, key, item) {
                    return item.call?.month
                }
            },
            {
                key: "loanTotalSum",
                label: "Общая сумма задолженности",
                sortable: true,
                formatter(value) {
                    return value.toLocaleString();
                }
            },
            {
                key: "status",
                label: "Статус",
            },
            {
                key: "checkPlannedAt",
                label: "Обещал оплатить",
                sortable: true,
                formatter(value, key, item) {
                    return window.formatDatetime(item.checkPlannedAt);
                },
            },
            {
                key: "planEndAt",
                sortable: true,
                label: "Дней просрочки",
                formatter(value, key, item) {
                    return item.data.delayDays;
                },
            },
            {
                key: "phone",
                sortable: true,
                label: "Мобильный",
                formatter(value, key, item) {
                    return item.phone;
                },
            },
            {
                key: "contacts",
                label: "Контакт третьего лица",
                formatter(value, key, item) {
                    return item.borrower.contacts.map((contact) => `${contact.phone} ${contact.name}`).join("\n");
                },
            },
            this.userFilter ? {
                key: "collector",
                label: "Коллектор",
                formatter(value, key, item) {
                    return item.collector ? item.collector.name : "-";
                },
            } : null,
            {
                key: "telegramUsername",
                label: "Аккаунт коллектора",
            }
        ]).filter((el) => el !== null);

        if (this.sortBy === null) {
            this.sortBy = "lastActionDate";
            this.sortDesc = true;
        }
    },
    methods: {
        formateTelegram(data){
            return data?.item?.collector?.telegramUsername ? `t.me/${data.item.collector.telegramUsername}` : ""
        },
        onQueryChange(query) {
            this.filterForm = {
                userId: this.userFilter ? (this.$route.query.userId ?? 0) : this.$auth.user().id,
                useNowTime: !this.$route.query.time,
                date: (this.$route.query.time ? moment(this.$route.query.time) : moment()).format("YYYY-MM-DD"),
                time: (this.$route.query.time ? moment(this.$route.query.time) : moment()).format("HH:mm:ss"),
                status: this.$route.query.status ?? 0,
                id: this.$route.query.id ?? "",
                fio: this.$route.query.fio ?? "",
                phone: this.$route.query.phone ?? "",
            };
            this.filters = {...this.filterForm};

            if (!query.currentPage || !query.perPage) {
                this.$router.replace({query: this._getQueryParams({})}).catch(() => {});
            }
        },
        formateStatus(data) {
            const found = this.statuses.find((status) => status.value === data.item.status);
            return found ? found.text : "неподдерживаемый статус";
        },
        _getQueryParams(options) {
            const filters = this.filters || {};
            const mometDate = filters.date ? moment(`${filters.date}T${filters.time}`).format() : null;
            const params = Object.assign({
                currentPage: this.pagination.currentPage,
                perPage: this.pagination.perPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                userId: parseInt(filters.userId),
                time: filters.useNowTime ? null : mometDate,
                status: filters.status ?? null,
                id: filters.id,
                fio: filters.fio,
                phone: filters.phone,
            }, options);

            return Object.fromEntries(Object.entries(params).filter(([, v]) => v != null && !!v));
        },
        request(query) {
            return this.jsonRpc("admin.loans.list", query)                
                .then(async (result) => {
                    this.errorServe = false;
                    this.pagination.totalItems = result.loans.totalItems;

                    const loans = result.loans.items;

                    const borrowers = {};
                    result.borrowers.forEach((borrower) => borrowers[borrower.uuid] = borrower);

                    const loansData = {};
                    result.loansData.forEach((loanData) => loansData[loanData.extId] = loanData);

                    const callData = {};
                    result.callData.forEach((call) => callData[call.loanId] = call);

                    loans.map((loan) => {
                        loan.data = loansData[loan.extId];
                        loan.borrower = borrowers[loan.data.borrower.extId];
                        loan.call = callData[loan.id]

                        return loan;
                    })
                    


                    return loans;
                });
        },
        requestCSV() {
            this.jsonRpc("admin.loans.exportListToCsv", this._getQueryParams({})).then(result => {
            
                let filename = "loan-export-" + new Date().toISOString().slice(0, 10);
                let csvFile = result;

                let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, filename);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) {
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", filename);
                        link.style.visibility = "hidden";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }).catch(e => {
                console.log(e)
            })                
        },
        submitFilters() {
            this.pagination.currentPage = 1
            let phone = this.filterForm.phone.replace(/[^0-9]/ig, "");

            // убираем все спецсимволы, пробелы, проверяем что в итоге получается просто число.
            // если в числе знаков меньше чем 10 - ищем по вхождению
            // если в числе 10 знаков - добавляем +7 слева
            // если в числе 11 знаков - и в первом не 7 и не 8 - выдаем ошибку.
            // если в числе 11 знаков и в первом 8 - заменяем его на +7
            // если в числе 11 знаков и в первом 7 - заменяем его на +7
            // если в числе больше 11 знаков - выдаем ошибку.

            if (phone.length > 11 || (phone.length === 11 && phone[0] !== "8" && phone[0] !== "7")) {
                this.$alertify.error("Некорректный номер телефона");

                return false;
            }

            this.filters = Object.assign({}, this.filterForm);
            if (phone.length === 10) {
                this.filters.phone = `+7${phone}`;
            } else if (phone.length === 11 && phone[0] === "7") {
                this.filters.phone = `+${phone}`;
            } else if (phone.length === 11 && phone[0] === "8") {
                this.filters.phone = `+7${phone.substring(1)}`;
            } else {
                this.filters.phone = phone;
            }

            this.refresh();

            return false;
        },
    },

}